<template>

   
            
    <van-row justify="center"  style="width:100%;height: calc(var(--vh, 1vh) * 100);">
         <van-col  :span="24" >
            <div>
                <iframe class="gameframe" ref="gameframe" src="https://playofbox.com/" frameborder="0" @load="onLoad"/>
            </div>

         </van-col>
     </van-row>
 
         <!-- 底部    -->
    <van-row justify="center" >
         <van-col  :span="24" >
             <nav-bar />
         </van-col>
     </van-row>
 
 </template>
 <script>
     import NavBar from '@/components/NavBar'
     export default {
         name: "onlineMiniGames",
         setup(){
 
         },
         components: {
             NavBar,
         }
     }
 </script>
 <style>
 .gameframe {
     width: 100%;
     height: calc(var(--vh, 1vh) * 100);
 }
 </style>